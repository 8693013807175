.scrollbar-hidden::-webkit-scrollbar {
    display: none;
  }
  .scrollbar-hidden {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  .hero-unit {
    position: relative;
    height: 780px;
    background: url(../../assets//img/Homepage//business-person-looking-finance-graphs.jpg) no-repeat center center;
    background-size: cover;
    
}

.hero-unit::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.781); 
}
 
  .hero-unit hgroup {
   position: relative;
    margin: 3rem 0;
  }
  .hero-unit .card-icon { /* front of card */
    display: block;
    margin: 0.5rem auto 0;
    font-size: 2rem;
    color: white;
  }
  .hero-unit .clients {
    margin: 2rem auto;
  }
  .hero-unit .clients a {
    color: white;
  }
  .clients .fa-stack-2x {
    color: gray;
  }
  .clients:hover .fa-stack-2x {
    color: #333333;
  }
  
  /* Card design - front */
  .button.large {
    margin: 0;
    padding: 2.25rem;
  }
  .button.large a {
    color: white;
    font-size: 1.75rem;
    font-weight: 100;
    margin-top: 2rem;
  }
  
  
  
  /* Buttons Card Flipper 
   * Source: https://cssdeck.com/labs/rxcleo5w
   */
  .flip-cards {
    float: left;
    width: 100%;
    height: 100%;
    padding-left: 1rem;
  }
  
  .flip-cards li {
    float: left;
    width: 33.3%;
    height: 180px;
    position: relative;
    padding: 0;
  }
  
 
  
  
  
  
  
  .flip-cards .card-front,
  .flip-cards .card-back {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: -webkit-transform 0.6s;
    -moz-transition: -moz-transform 0.6s;
    -o-transition: -o-transform 0.6s;
    transition: transform 0.6s;
    display: block;
    height: 100%;
    position: absolute;
    width: 100%;
  }
  
  
  
  
  
  /* Button Card Colors */
  .flip-cards .panel {
    border: none;
  }
  
  .flip-cards .panel i,
  .flip-cards .panel p,
  .flip-cards .panel a,
  .flip-cards .panel small {
    color: white;
  }
  
  /* First button color */
  .flip-cards li:first-child > .button {
    background-color: rgba(40, 57, 77, 0.8);
  }
  .flip-cards li:first-child > .panel {
    background-color: rgba(40, 57, 77, 1);
  }
  
  /* Second button color */
  .flip-cards li:nth-child(2) > .button {
    background-color: rgba(109, 193, 202, 0.8);
  }
  .flip-cards li:nth-child(2) > .panel {
    background-color: rgba(109, 193, 202, 1);
  }
  
  /* Third button color */
  .flip-cards li:nth-child(3) > .button {
    background-color: rgba(211, 81, 45, 0.8);
  }
  .flip-cards li:nth-child(3) > .panel {
    background-color: rgba(211, 81, 45, 1);
  }
  
  /* Fourth button color */
  .flip-cards li:nth-child(4) > .button {
    background-color: rgba(235, 167, 36, 0.8);
  }
  .flip-cards li:nth-child(4) > .panel {
    background-color: rgba(235, 167, 36, 1);
  }
  
  /* Fifth button color */
  .flip-cards li:nth-child(5) > .button {
    background-color: rgba(75, 135, 150, 0.8);
  }
  .flip-cards li:nth-child(5) > .panel {
    background-color: rgba(75, 135, 150, 1);
  }
  
  /* Sixth (last) button color */
  .flip-cards li:last-child > .button {
    background-color: rgba(100, 66, 87, 0.8);
  }
  .flip-cards li:last-child > .panel {
    background-color: rgba(100, 66, 87, 1);
    color: white;
  }
  
  /*
   * RESPONSIVENESS -------------------------------------------------
   */
  @media only screen and ( max-width: 375px ) {
    .hero-unit {
      height: 1000px;
    }
    .flip-cards li {
      width: 50%;
      /*height: 160px;*/
    }
  }
  @media only screen and ( max-width: 768px ) {
    .hero-unit {
      height: 1450px;
    }
    .flip-cards li {
      width: 100%;
      /*height: 160px;*/
    }
  }
  @media only screen and ( max-width: 1124px ) {
    .hero-unit {
      height: 1450px;
    }
    .flip-cards li {
      width: 100%;
      /*height: 160px;*/
    }
  }
  @media only screen and ( max-width: 30em ) {
    .hero-unit h1 {
      font-size: 1.75rem;
    }
    .hero-unit h3 {
      font-size: 1.125rem;
    }
  }
  @media only screen and ( max-width: 25em ) {
    .hero-unit {
      height: 1500px;
    }
    .hero-unit hgroup {
      margin: 2rem 0;
    }
    .hero-unit h1 {
      font-size: 1.35rem;
      padding-bottom: 0.25rem;
      text-align: center;
    }
    .hero-unit h3 {
      font-size: 0.85rem;
      text-align: center;
    }
    .hero-unit .card-icon {
      font-size: 1.35rem;
    }
    .hero-unit .card-back .card-icon {
      font-size: 2rem;
      margin: 0;
      padding: 5px 0 20px 5px;
    }
    .flip-cards li {
      width: 100%;
      font-size: 1.35rem;
      height: 180px;
    }
    .flip-cards .panel p {
      display: none;  
    }
    .flip-cards .panel small {
      margin: 0;
    }
    .button.large {
      padding: 2rem;
    }
    .button.large a {
      font-size: 1.35rem;
    }
  }