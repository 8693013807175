.flow > * + * {
    margin-top: var(--flow-space, 1em);
   
  }
  
  /* CARD COMPONENT */
  
  .card {
    display: grid;
    place-items: center;
    overflow: hidden;
    border-radius: 0.625rem;
    box-shadow: 0.25rem 0.25rem 0.5rem rgba(0, 0, 0, 0.25);
  }
  
  .card > * {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
  }
  
  .card__background {
    object-fit: cover;
    max-width: 100%;
    height: 100%;
  }
  
  .card__content {
    --flow-space: 0.9375rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
   
    height: 100%;
    padding: 12% 1.25rem 1.875rem;
    background: linear-gradient(
      180deg,
      hsla(0, 0%, 0%, 0) 0%,
      hsla(0, 0%, 0%, 0.3) 10%,
      hsl(0, 0%, 0%) 100%
    );
  }
  
  .card__content--container {
    --flow-space: 1.25rem;
  }
  
  .card__title {
    position: relative;
    width: fit-content;
   
  }
  
  .card__title::after {
    content: "";
    position: absolute;
    height: 0.3125rem;
    width: calc(100% + 1.25rem);
    bottom: calc((1.25rem - 0.5rem) * -1);
    left: -1.25rem;
    background-color: #ebde25;
  }
  
  .card__button {
   padding: 8px;
    width: fit-content;
    width: -moz-fit-content;
    border-radius: 0.45em;
    border: none;

    font-size: 1.125rem;
    
  }
  
  .card__button:focus {
    outline: 2px solid #2563eb;
    outline-offset: -5px;
  }
  
  @media (any-hover: hover) and (any-pointer: fine) {
    .card__content {
      transform: translateY(62%);
      transition: transform 500ms ease-out;
      transition-delay: 500ms;
    }
  
    .card__title::after {
      opacity: 0;
      transform: scaleX(0);
      transition: opacity 1000ms ease-in, transform 500ms ease-out;
      transition-delay: 500ms;
      transform-origin: right;
    }
  
    .card__background {
      transition: transform 500ms ease-in;
    }
  
    .card__content--container > :not(.card__title),
    .card__button {
      opacity: 0;
      transition: transform 500ms ease-out, opacity 500ms ease-out;
    }
  
    .card:hover,
    .card:focus-within {
      transform: scale(1.05);
      transition: transform 500ms ease-in;
    }
  
    .card:hover .card__content,
    .card:focus-within .card__content {
      transform: translateY(0);
      transition: transform 500ms ease-in;
    }
  
    .card:focus-within .card__content {
      transition-duration: 0ms;
    }
  
    .card:hover .card__background,
    .card:focus-within .card__background {
      transform: scale(1.3);
    }
  
    .card:hover .card__content--container > :not(.card__title),
    .card:hover .card__button,
    .card:focus-within .card__content--container > :not(.card__title),
    .card:focus-within .card__button {
      opacity: 1;
      transition: opacity 500ms ease-in;
      transition-delay: 1000ms;
    }
  
    .card:hover .card__title::after,
    .card:focus-within .card__title::after {
      opacity: 1;
      transform: scaleX(1);
      transform-origin: left;
      transition: opacity 500ms ease-in, transform 500ms ease-in;
      transition-delay: 500ms;
    }
  }
/* emerging section css */
