.main-timeline {
    
    padding: 20px 0;
    position: relative;
  }
  
  .main-timeline::before,
  .main-timeline::after {
    content: "";
    height: 40px;
    width: 40px;
    background-color: #0f172a;
    border-radius: 50%;
    border: 10px solid #303334;
    transform: translatex(-50%);
    position: absolute;
    left: 50%;
    top: -15px;
    z-index: 2;
  }
  
  .main-timeline::after {
    top: auto;
    bottom: 15px;
  }
  
  .main-timeline .timeline {
    padding: 35px 0;
    margin-top: -30px;
    position: relative;
    z-index: 1;
  }
  
  .main-timeline .timeline::before,
  .main-timeline .timeline::after {
    content: "";
    height: 100%;
    width: 50%;
    border-radius: 110px 0 0 110px;
    border: 15px solid #46b2bc;
    border-right: none;
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
  }
  
  .main-timeline .timeline::after {
    height: calc(100% - 30px);
    width: calc(50% - 12px);
    border-color: #65c7d0;
    left: 12px;
    top: 15px;
  }
  
  .main-timeline .timeline-content {
    display: inline-block;
    width: 100%;
   padding: 20px;
  }
  
  .main-timeline .timeline-content:hover {
    text-decoration: none;
  }
  
  .main-timeline .timeline-year {
    color: #65c7d0;
    font-size: 50px;
    font-weight: 600;
    display: inline-block;
    transform: translatey(-50%);
    position: absolute;
    top: 50%;
    left: 10%;
  }
  
  .main-timeline .timeline-icon {
      color: #65c7d0;
      font-size: 70px;
      display: inline-block;
      transform: translateY(-50%);
      position: absolute;
      left: 30%;
      top: 50%;
  }
  
  .main-timeline .content {
    color: #909090;
    width: 50%;
    padding: 20px;
  
    float: right;
  }
  
  .main-timeline .title {
    color: #65c7d0;
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0 0 5px 0;
  }
  
  .main-timeline .description {
  
    margin: 0;
  }
  
  .main-timeline .timeline:nth-child(even)::before {
    left: auto;
    right: 0;
    border-radius: 0 110px 110px 0;
    border: 15px solid red;
    border-left: none;
  }
  
  .main-timeline .timeline:nth-child(even)::after {
    left: auto;
    right: 12px;
    border-radius: 0 100px 100px 0;
    border: 15px solid green;
    border-left: none;
  }
  
  .main-timeline .timeline:nth-child(even) .content {
    float: left;
  }
 
  
  .main-timeline .timeline:nth-child(even) .timeline-year {
      left: auto;
      right: 10%;
  }
  
  .main-timeline .timeline:nth-child(even) .timeline-icon {
    left: auto;
    right: 32%;
  }
  
  .main-timeline .timeline:nth-child(5n+1)::before {
    border-color: #46b2bc;
  }
  
  .main-timeline .timeline:nth-child(5n+1)::after {
    border-color: #65c7d0;
  }
  
  .main-timeline .timeline:nth-child(5n+1) .timeline-icon {
    color: #65c7d0;
  }
  
  .main-timeline .timeline:nth-child(5n+1) .timeline-year {
    color: #65c7d0;
  }
  
  .main-timeline .timeline:nth-child(5n+1) .title {
    color: #65c7d0;
  }
  
  .main-timeline .timeline:nth-child(5n+2)::before {
    border-color: #e65636fd;
  }
  
  .main-timeline .timeline:nth-child(5n+2)::after {
    border-color: #f46835;
  }
  
  .main-timeline .timeline:nth-child(5n+2) .timeline-icon {
    color: #f86645;
  }
  
  .main-timeline .timeline:nth-child(5n+2) .timeline-year {
    color: #f86645;
  }
  
  .main-timeline .timeline:nth-child(5n+2) .title {
    color: #f86645;
  }
  
  .main-timeline .timeline:nth-child(5n+3)::before {
    border-color: #ade06a;
  }
  
  .main-timeline .timeline:nth-child(5n+3)::after {
    border-color: #a2d273;
  }
  
  .main-timeline .timeline:nth-child(5n+3) .timeline-icon
  {
    color: #b1da7a;
  }
  
  .main-timeline .timeline:nth-child(5n+3) .timeline-year {
    color: #b1da7a;
  }
  
  .main-timeline .timeline:nth-child(5n+3) .title {
    color: #b1da7a;
  }
  
  .main-timeline .timeline:nth-child(5n+4)::before {
    border-color: #f1a049;
  }
  
  .main-timeline .timeline:nth-child(5n+4)::after {
    border-color: #f1a049;
  }
  
  .main-timeline .timeline:nth-child(5n+4) .timeline-icon {
    color: #f1a049;
  }
  
  .main-timeline .timeline:nth-child(5n+4) .timeline-year {
    color: #f1a049;
  }
  
  .main-timeline .timeline:nth-child(5n+4) .title {
    color: #f1a049;
  }
  
  .main-timeline .timeline:nth-child(5n+5)::before {
    border-color: #3191d2;
  }
  
  .main-timeline .timeline:nth-child(5n+5)::after {
    border-color: #2f7acb;
  }
  
  .main-timeline .timeline:nth-child(5n+5) .timeline-icon {
    color: #0071BD;
  }
  
  .main-timeline .timeline:nth-child(5n+5) .timeline-year {
    color: #0071BD;
  }
  
  .main-timeline .timeline:nth-child(5n+5) .title {
    color: #0071BD;
  }
  
  @media screen and (max-width:1200px){
      .main-timeline .timeline:after{ border-radius: 88px 0 0 88px; }
      .main-timeline .timeline:nth-child(even):after{ border-radius: 0 88px 88px 0; }
  }
  @media screen and (max-width:767px){
      .main-timeline .timeline{ margin-top: -19px; }
      .main-timeline .timeline:before {
          border-radius: 50px 0 0 50px;
          border-width: 10px;
      }
      .main-timeline .timeline:after {
          height: calc(100% - 18px);
          width: calc(50% - 9px);
          border-radius: 43px 0 0 43px;
          border-width:10px;
          top: 9px;
          left: 9px;
      }
      .main-timeline .timeline:nth-child(even):before {
          border-radius: 0 50px 50px 0;
          border-width: 10px;
      }
      .main-timeline .timeline:nth-child(even):after {
          height: calc(100% - 18px);
          width: calc(50% - 9px);
          border-radius: 0 43px 43px 0;
          border-width: 10px;
          top: 9px;
          right: 9px;
      }
      .main-timeline .timeline-icon{ font-size: 60px; }
      .main-timeline .timeline-year{ font-size: 40px; }
  }
  @media screen and (max-width:479px){
      .main-timeline .timeline-icon{
          font-size: 50px;
          transform:translateY(0);
          top: 25%;
          left: 10%;
      }
      .main-timeline .timeline-year{
          font-size: 25px;
          transform:translateY(0);
          top: 65%;
          left: 9%;
      }
      .main-timeline .content{
          width: 68%;
          padding: 10px;
      }
      .main-timeline .title{ font-size: 18px; }
      .main-timeline .timeline:nth-child(even) .timeline-icon{
          right: 10%;
      }
      .main-timeline .timeline:nth-child(even) .timeline-year{
          right: 9%;
      }
  }